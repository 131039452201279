import React, {useEffect, useState} from 'react'
import {Col, Row, Modal} from "reactstrap"

import {withTranslation} from "react-i18next"
import LegalIdInput from "../../components/Common/LegalIdInput"
import {post, get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper"
import Loader from "../../components/Common/Loader";
import ErrorDialog from "../../components/Common/ErrorDialog";
import FormUtils from "../../components/Common/FormUtils";
import Select2Generic from "../../components/Common/Select2Generic";
import OrganizationProfileDomain from "../../domains/OrganizationProfileDomain";

function OrganizationNew (props)  {

    useEffect(()=>{
        if (!didMpunt){
            setDidMpunt(true)
            loadSegments()
        }
    })

    const [innerHeight] = useState(window.innerHeight)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [isOpen, setIsOpen] = useState(props.isOpen)
    const [didMpunt, setDidMpunt] = useState(false)

    const [state, setState] = useState( {
        entity: {
            legalId: null,
            appProfile: null,
            autoCreateOptin: false,
            segment: null
        },
        form: {
            legalId: {isValid: false}
        },
        companySegments: []
    })

    const isValid = () => {
        return FormUtils.isValid(state.form)
    }

    const setStateCore = (newState) => {
        setState(Object.assign({}, newState))
    }

    const loadSegments = async () => {
        const response = await get(
            `${url.GET_SEGMENTS}?pagination.currentPage=0&pagination.itemsPerPage=100`, null, null, setLoading
        )
        state.companySegments = response.segments.map(s => {
            return {label: s.segmentLabel, value: s.segmentName }
        })
        setStateCore(state)
    }

    const toggle = () => {
        setIsOpen(false)
    }

    const afterClosed = () => {
        props.callback({isOpen: false, entity: null, event: 'CLOSE'})
    }

    const close = (newEntity) => {
        props.callback({isOpen: false, entity: newEntity, event: 'SAVED'})
    }

    const onChangeValidation = (field, isValid) => {
        state.form[field] = {isValid: isValid}
        setStateCore(state)
    }

    const handleFieldEntity = (name, value) => {
        state.entity[name] = value
        setStateCore(state)
    }

    const normalizedFieldValue = (value) => {
        return value !== null && value !== undefined && value.toString().length > 0 ? value.toString().trim() : null
    }

    const save = async () => {
        if (state.loading) return
        if (!isValid(state.form)) return
        try {
            const response = await post(
                url.POST_ORGANIZATION, state.entity, null, setLoading
            )
            close(response['organization'])
        } catch (e) {
            if (e['status'] !== '200' && e['status'] !== '403')
                setError(e)
        }
    }

        return (
            <React.Fragment>
                <Modal
                    isOpen={isOpen}
                    toggle={() => {
                        toggle()
                    }}

                    onClosed={() => {
                        afterClosed()
                    }}
                    modalClassName="drawer right-align"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            {props.t("Register Organization")}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                toggle()
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <Row>
                            <Col lg={12} className="mb-3">
                                <LegalIdInput
                                    id="legalId"
                                    required={true}
                                    autoComplete="off"
                                    onChangeValidation={onChangeValidation}
                                    onChange={event => {
                                        handleFieldEntity("legalId",
                                            normalizedFieldValue(event.target.value)
                                        )
                                    }}
                                    label={props.t("LegalId")}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className="mb-3">
                                <Select2Generic
                                    id="appProfile"
                                    required={true}
                                    defaultOptins={OrganizationProfileDomain.options}
                                    label={props.t("Profile")}
                                    onChange={(option) => {
                                        handleFieldEntity("appProfile", option.value)
                                        handleFieldEntity("autoCreateOptin", false)
                                    }}/>
                            </Col>
                        </Row>
                        {
                            state.entity.appProfile === 'SELLER'?
                                <Row>
                                    <Col lg={12} className="mb-3">
                                        <Select2Generic
                                            id="segment"
                                            required={false}
                                            defaultOptins={state.companySegments}
                                            label={props.t("Segment")}
                                            onChange={(option) => {
                                                handleFieldEntity("segment", option.value)
                                            }}/>
                                    </Col>
                                </Row> : ("")
                        }

                        {
                            state.entity.appProfile === 'END_USER'?
                                <Row className="mt-2">
                                    <Col lg={12} className="mb-3">
                                        <div class="form-check form-check-active">
                                            <input class="form-check-input" type="checkbox"
                                                   name="requestSigning" id="requestSigning"
                                                   onChange={(event) => {
                                                       handleFieldEntity("autoCreateOptin", event.target.checked)
                                                   }}
                                            />
                                            <label for="requestSigning" class="form-check-label">
                                                {props.t("Create Automatic Opt-in and Consult Receivables Schedule")}
                                            </label>
                                        </div>
                                    </Col>
                                </Row> : ("")
                        }

                    </div>

                    <div className="modal-footer fixed-bottom">

                        <div className="text-end">
                            <button
                                className="btn btn-primary w-sm waves-effect waves-light"
                                onClick={save}
                            >
                                {props.t("Save")}
                            </button>
                        </div>

                    </div>

                    {
                        error ? (
                            <ErrorDialog
                                title={error['title']}
                                onClose={() => {
                                    setError(null)
                                }}
                            >
                                <p>{error['detail']} </p>
                            </ErrorDialog>
                        ) : ("")
                    }

                    {
                        loading ?
                            <Loader loading={loading}/>
                            :
                            (" ")
                    }

                </Modal>

            </React.Fragment>
        )
}


export default withTranslation()(OrganizationNew)