import React, {useState} from 'react'
import {Col, Row, Modal} from "reactstrap"

import {withTranslation} from "react-i18next"
import Select2Banks from "./Select2Banks";
import Select2AccountType from "./Select2AccountType";
import InputNumber from "./InputNumber";

function SelectAddAccountModal(props)  {

    const [isOpen, setIsOpen] = useState(true)
    const [entity, setEntity] = useState({

    })

    const toggle = () => {
        setIsOpen(false)
    }

    const afterClosed = () => {
        props.callback({isOpen: false, entity: null, event: 'CLOSE'})
    }

    const close = (newEntity) => {
        props.callback({isOpen: false, entity: newEntity, event: 'SAVED'})
    }

    const add = () => {
        close(entity)
    }

    const handleField = (name, value) => {
        entity[name] = value
        setEntity(entity)
    }

    return (
            <React.Fragment>
                <Modal
                    isOpen={isOpen}
                    toggle={() => {
                        toggle()
                    }}
                    onClosed={() => {
                        afterClosed()
                    }}
                    modalClassName="drawer right-align"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            {
                                props.title ? props.title :
                                    props.t("Info Bank Account")
                            }
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                toggle()
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <Row>
                            <Col lg={12}>
                                <Select2Banks required={true}
                                              onChange={(bank) => {
                                                  handleField('ispb', bank.ispb)
                                              }}
                                              label={props.t("Instituição financeira")}/>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12} className="mt-3">
                                <Select2AccountType
                                    required={true}
                                    onChange={(option) => {
                                        handleField('accountType', option.value)
                                    }}
                                    label={props.t("Account type")}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} className="mb-3">
                                <InputNumber required={true}
                                             maxLength={4}
                                             onChange={(event) => {
                                                 handleField('branch', event.target.value)
                                             }}
                                             label={props.t("Branch")}/>
                            </Col>
                            <Col lg={6} className="mb-3">
                                <InputNumber required={true}
                                             maxLength={20}
                                             onChange={(event) => {
                                                 handleField('accountNumber', event.target.value)
                                             }}
                                             label={props.t("Account number")}/>
                            </Col>
                            <Col lg={2} className="mb-3">
                                <InputNumber
                                    maxLength={1}
                                    onChange={(event) => {
                                        handleField('accountDigit', event.target.value)
                                    }}
                                    label={props.t("Account Digit")}/>
                            </Col>
                        </Row>
                    </div>

                    <div className="modal-footer">

                        <div className="text-end">
                            <button
                                className="btn btn-primary w-sm waves-effect waves-light"
                                onClick={()=> {
                                    add()
                                }}
                            >
                                {props.t("Add")}
                            </button>
                        </div>

                    </div>

                </Modal>

            </React.Fragment>
        )
}


export default withTranslation()(SelectAddAccountModal)