import React, {useState} from 'react'
import {Label, PopoverBody, PopoverHeader, UncontrolledPopover} from "reactstrap"
import {withTranslation} from "react-i18next"

function InputText (props) {

    const [state, setState] = useState({
        isValid: true
    })

    const setValue = (event) => {
        let isValid = validation(event)
        if (props.onChange) {
            props.onChange(event)
        }
        if (!state.isValid && isValid){
            state.isValid = isValid
        }
        setState(Object.assign({}, state))
    }

    const [showPopover, setShowPopover] = useState(false)
    const [popoverBtnId] = useState(`popoverBtn${props.id}`)

    const onChangeValidation = (event) => {
        state.isValid = validation(event)
        setState(Object.assign({}, state))
    }

    const validation = (event) => {
        let isValid = true
        if (props.validation){
            isValid = props.validation(event.target.value)
        }
        if (props.onChangeValidation) {
            props.onChangeValidation(isValid)
        }
        return isValid
    }

    return (<React.Fragment>
            {
                props.label !== null && props.label !== undefined ?
                    <Label for={props.id} className="form-label">
                        {`${props.required === true ? "*" : ""}${props.label}`}
                    </Label>
                    : ("")
            }
            {
                props.popover !== null && props.popover !== undefined ?
                    <button id={popoverBtnId}
                            style={{height: 20}}
                            className="btn btn-without-class"
                            onClick={() => {
                                setShowPopover(!showPopover)
                            }}>
                        <i className="bx bxs-help-circle font-size-20"  />
                    </button>
                    : ("")
            }
            {
                props.popover !== null && props.popover !== undefined ?
                    <UncontrolledPopover
                        placement="bottom"
                        trigger="focus"
                        target={popoverBtnId}
                    >
                        {
                            props.label !== null && props.label !== undefined ?
                                <PopoverHeader>{props.label}</PopoverHeader>
                                : ("")
                        }
                        <PopoverBody>
                            {props.popover}
                        </PopoverBody>
                    </UncontrolledPopover>
                    : ("")
            }
            <input type="text"
                   className={state.isValid ? "form-control" : "form-control is-invalid"}
                   id={props.id}
                   name={props.id}
                   onChange={setValue}
                   onBlur={onChangeValidation}
                   list={props.datalist ? `${props.id}-datalist` : null}
                   value={props.value !== undefined ? props.value : null}
                   readOnly={
                       props.readOnly !== null && props.readOnly !== undefined ?
                           props.readOnly : false
                   }
                   autoComplete={props.autoComplete ? props.autoComplete : "off"}
                   placeholder={props.placeholder}/>

            {
                props.datalist ?

                    <datalist id={`${props.id}-datalist`}>

                        {(props.datalist || []).map((str, key) => (
                            <React.Fragment>
                                <option value={str} />
                            </React.Fragment>
                        ))
                        }
                    </datalist>
                    : ("")
            }

        </React.Fragment>)
}

export default withTranslation()(InputText)